<template>
  <el-row class="hz-row" :gutter="24">
    <el-col :span="12">
      <el-button type="primary" @click="addDialog">
        <el-icon style="vertical-align: middle">
          <Plus />
        </el-icon>
        <span style="vertical-align: middle"> 新增用户 </span>
      </el-button>
    </el-col>
    <el-col :span="4">
    </el-col>
    <el-col :span="8">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-input v-model="searchName" placeholder="输入账号"></el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="init()">
            <el-icon style="vertical-align: middle">
              <Search />
            </el-icon>
            <span style="vertical-align: middle"> 搜索 </span>
          </el-button>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
  <el-divider></el-divider>
  <div>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="account" label="账号" />
      <el-table-column prop="rolename" label="角色" />
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag :type="scope.row.status === 0 ? 'success' : 'info'" disable-transitions>{{ scope.row.status == 0 ? "启用"
            : "禁用"
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button size="small" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="small" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="pagin">
    <el-pagination small background layout="prev, pager, next" :total="total" class="mt-4"
      @current-change="handleCurrentChange" />
  </div>
  <el-dialog v-model="dialogVisible" :title="dialogTitle" width="30%" draggable>
    <Add @closeDialog="closeDialog" ref="addUser" v-if="dialogTitle == '新增账号'"></Add>
    <Edit v-else-if="dialogTitle == '编辑账号'" @closeDialog="closeDialog" ref="editUser"></Edit>
  </el-dialog>
</template>

<script>
import Add from './add.vue'
import Edit from './edit.vue'
import { ElMessage} from 'element-plus'
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 10,
      small: false,
      background: true,
      tableData: [],
      total: 0,
      dialogVisible: false,
      dialogTitle: '新增账号',
      searchName:''
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let _this = this;
      this.$https.get('api/admin/users/getlist', {
        params: {
          page: _this.currentPage,
          limit: _this.pageSize,
          account:_this.searchName
        }
      }).then(res => {
        if (res.success) {
          var result = res.data;
          _this.total = result.count;
          _this.tableData = result.data;
        }
      })
    },
    handleEdit(index, item) {
      this.dialogVisible = true;
      this.dialogTitle = '编辑账号'
      this.$nextTick(() => {
        this.$refs.editUser.editForm(item);
      })
    },
    handleDelete(index, item) {
      const _this = this;
      this.$https.get('api/admin/users/del', { params: { id: item.id } }).then(res => {
        if (res.success) {
          ElMessage({
            type: 'success',
            message: res.msg
          })
          _this.init();
        }
      })
    },
    //点击页码事件
    handleCurrentChange(value) {
      this.currentPage = value
      this.init();
    },
    //弹出新增账号界面
    addDialog() {
      this.dialogVisible = true;
      this.dialogTitle = '新增账号'
    },
    //关闭弹窗
    closeDialog() {
      this.dialogVisible = false;
      this.init();
    }
  },
  components: {
    Add,
    Edit
  }
}
</script>

<style>
.pagin {
  margin-top: 10px;
}
</style>