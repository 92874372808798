<template>
    <el-divider></el-divider>
    <el-form ref="formRef" :model="formodel" label-width="100px">
        <el-form-item label="所属角色" :model="formodel.roleid">
            <el-select v-model="formodel.roleid" placeholder="请选择角色">
                <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id" />
            </el-select>
        </el-form-item>
        <el-form-item label="账号">
            <el-input v-model="formodel.account" placeholder="请输入账号" />
        </el-form-item>
        <el-form-item label="密码">
            <el-input  v-model="formodel.password" placeholder="请输入密码"   type="password"/>
        </el-form-item>
        <el-form-item label="邮箱">
            <el-input v-model="formodel.email"   placeholder="请输入邮箱"/>
        </el-form-item>
        <el-form-item label="手机号码">
            <el-input v-model="formodel.phone"   placeholder="请输入手机号码"/>
        </el-form-item>
        <el-form-item label="状态">
            <el-switch v-model="formodel.status"  :active-value="0" :inactive-value="1"/>
        </el-form-item>
        <el-form-item>
            <el-button type="info" @click="clearForm">清空</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
export default {
    data() {
        return {
            formodel: {
                account: '',//名称
                password: '',//路径
                roleid: '',//角色
                email: '',//邮箱
                phone: '',//手机号码
                status: 0 //状态
            },
            roleList:[]
        }
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            let _this=this;
            this.$https.get('api/admin/roles/GetList',{ param:{ limit:9999 }}).then(res => {
                if(res.success){
                    var result=res.data;
                    _this.roleList=result.data;
                }
            })
        },
        onSubmit() {
            const _this = this;
            this.$https.post('api/Admin/users/insertOrUpdate', _this.formodel).then(res => {
                if (res.success) { 
                    _this.clearForm()
                    _this.$emit('closeDialog');
                }
            })
        },
        //清空
        clearForm() {
            this.formodel.account = ''
            this.formodel.password = ''
            this.formodel.roleid = ''
            this.formodel.email =''
            this.formodel.phone = ''
            this.formodel.status = 1
        }
    }
}
</script>